import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'offers',
    path: '/offers',
    component: () => import('../pages/OffersPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.OFFER_LIST
    }
  },
  {
    name: 'offerContractDetails',
    path: '/offers/contract/:contractCode',
    component: () => import('../pages/OfferOrderPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      permission: Permission.OFFER_CONTRACT
    }
  },
  {
    name: 'offerDetails',
    path: '/offers/:offerId',
    component: () => import('../pages/OfferDetailsPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.OFFER_DETAILS
    },
    children: [
      {
        name: 'offerProductsTab',
        path: '',
        component: () => import('../pages/OfferProductsPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'offerParcelsTab',
        path: 'parcels',
        component: () => import('../pages/OfferParcelsPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'offerAvailableOrdersTab',
        path: 'available-orders',
        component: () => import('../pages/OfferAvailableOrdersPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ],
    props: (route) => ({
      offerId: route.params.offerId,
      productTabName: 'offerProductsTab',
      parcelTabName: 'offerParcelsTab',
      availableOrdersTabName: 'offerAvailableOrdersTab'
    })
  }
];

export default routes;
