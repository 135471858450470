import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'customers',
    path: '/customers',
    component: () => import('../pages/CustomersPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.CUSTOMER_LIST
    }
  },
  {
    path: '/customers/create',
    component: () => import('../pages/CustomerDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'customerOffers',
    path: '/customers/:customerId/offers',
    component: () => import('../pages/CustomerOffersPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'customerOfferDetails',
    path: '/customers/:customerId/offers/:offerId',
    component: () => import('../../offer/pages/OfferDetailsPage.vue'),
    props: (route) => ({
      customerId: route.params.customerId,
      offerId: route.params.offerId,
      productTabName: 'customerOfferProductsTab',
      parcelTabName: 'customerOfferParcelsTab',
      availableOrdersTabName: 'customerOfferAvailableOrdersTab'
    }),
    children: [
      {
        name: 'customerOfferProductsTab',
        path: '',
        component: () => import('../../offer/pages/OfferProductsPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'customerOfferParcelsTab',
        path: 'parcels',
        component: () => import('../../offer/pages/OfferParcelsPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'customerOfferAvailableOrdersTab',
        path: 'available-orders',
        component: () => import('../../offer/pages/OfferAvailableOrdersPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers/:customerId',
    component: () => import('../pages/CustomerDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      permission: Permission.CUSTOMER_EDIT
    }
  }
];

export default routes;
